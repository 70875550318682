import { render, staticRenderFns } from "./forget.vue?vue&type=template&id=49d2180d&scoped=true&"
import script from "./forget.vue?vue&type=script&lang=js&"
export * from "./forget.vue?vue&type=script&lang=js&"
import style0 from "./forget.vue?vue&type=style&index=0&id=49d2180d&lang=less&scoped=true&"
import style1 from "./forget.vue?vue&type=style&index=1&id=49d2180d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d2180d",
  null
  
)

export default component.exports